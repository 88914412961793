import { useEffect, useState } from "react";
import { useAuthProvider } from "../../core/authContext";
import { useGetActiveItinerariesQuery,useGetClosedItinerariesQuery } from "../../generated/graphql";
import { endOfMonth,startOfMonth } from "date-fns";


const useItinerary = () => {
  const { getUser } = useAuthProvider();
  const property = getUser();
  const [startDate, setStartDate] = useState<string>(startOfMonth( new Date()).toISOString());
  const [endDate, setEndDate] = useState<string>(endOfMonth( new Date()).toISOString());
  const [activeList, setActiveList] = useState<any>([]);
  const [closedList, setClosedList] = useState<any>([]);

  const { data:activeItineraryList, loading: loadingActiveItineraryList } = useGetActiveItinerariesQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: property?.id,
      startDate: startDate,
      endDate: endDate,
    },
    skip:!property?.id || !startDate || !endDate
  });

  const { data:closeItineraryList, loading: loadingCloseItineraryList } = useGetClosedItinerariesQuery({
    fetchPolicy: "no-cache",
    variables: {
      propertyID: property?.id,
      startDate: startDate,
    },
    skip:!property?.id || !startDate
  });


  useEffect(() => {
    if (activeItineraryList?.getActiveItineraries || closeItineraryList?.getClosedItineraries) {
      const sortedActiveList = activeItineraryList?.getActiveItineraries
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
        const sortedClosedList = closeItineraryList?.getClosedItineraries
        ?.slice()
        .sort(
          (a: any, b: any) =>
            new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        );
        setActiveList(sortedActiveList);
        setClosedList(sortedClosedList);
    } else {
      setActiveList([]);
      setClosedList([]);
    }
  }, [activeItineraryList,closeItineraryList]);


  return {
    activeList,
    closedList,
    loading:loadingActiveItineraryList || loadingCloseItineraryList,
    setStartDate,
    setEndDate,
  };
};

export default useItinerary;
