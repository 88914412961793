import { Routes, Route } from "react-router-dom";
import { memo } from "react";
import Layout from "../layout";
import HotelInformation from "./Hotel-Information";
import Room from "./Rooms";
import HotelServices from "./Hotel-Services";
import EditService from "./Edit-Hotel-Services";
import HotelEdible from "./Dine-In-Menu";
import Promotions from "./Promotions";
import HotelCategories from "./Dine-In-Categories";
import HotelSections from "./Sections";
import HotelAllergens from "./Allregens";
import * as React from "react";
import Reports from "./Reports";
import Services from "./Services";
import Itinerary from "./Itinerary";


const Routing = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HotelInformation />} />
        <Route path="/rooms" element={<Room />} />
        {/* <Route path="/dining" element={<DineInMenu />} /> */}
        <Route path="/hotel-services">
          <Route index element={<HotelServices />}/>
          <Route path=":serviceID" element={<EditService/>}/>
          <Route path="add-service" element={<EditService/>}/>
        </Route>
        <Route path="/services" element={<Services />} />
        <Route path="/hotel-edible" element={<HotelEdible />} />
        <Route path="/promotions" element={<Promotions />} />
        <Route path="/categories" element={<HotelCategories/>} />
        <Route path="/sections" element={<HotelSections/>} />
        <Route path="/allergens" element={<HotelAllergens/>} />
        <Route path="/reports" element={<Reports/>} />
        <Route path="/itinerary" element={<Itinerary/>}/>
      </Routes>
    </Layout>
  );
};

export default memo(Routing);
