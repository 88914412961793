import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  GridExperimentalFeatures,
} from "@mui/x-data-grid";

type Props = {
  itineraryList: { [key: string]: any }[] | [];
};
export default function ItineraryTable({ itineraryList }: Props) {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        <GridToolbarFilterButton
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  function getStartDate(params: GridValueGetterParams) {
    return new Date(params.row.start_date).toLocaleDateString()
  }
  function getEndDate(params: GridValueGetterParams) {
    return new Date(params.row.end_date).toLocaleDateString()
  }

  const columnsForItineraryList: GridColDef[] = [
    {
      field: "start_date",
      headerName: "Start Date",
      width: 300,
      // minWidth:200,
      editable: false,
      sortable: false,
      valueGetter: getStartDate,
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 300,
      // minWidth:200,
      editable: false,
      sortable: false,
      valueGetter: getEndDate,
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      width: 250,
    },
    {
      field: "time",
      headerName: "Time",
      // width: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
    },
    {
        field: "venue",
        headerName: "Venue",
        width: 250,
        // minWidth:200,
        editable: false,
        sortable: false,
      },
  ];

  return (
    <Box sx={{ height: 650, width: "100%" }}>
      <DataGrid
        rows={itineraryList || []}
        columns={columnsForItineraryList}
        pageSize={9}
        rowsPerPageOptions={[9]}
        density="comfortable"
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={
          { newEditingApi: true } as Partial<GridExperimentalFeatures>
        }
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  );
}
